import React, { useEffect, useState } from 'react';
import * as Modal from '../../components/Atoms/Modal';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { Panel } from 'primereact/panel';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Divider } from 'primereact/divider';
import { Button } from 'primereact/button';
import { RadioButton } from 'primereact/radiobutton';
import ReportDialog from './ReportDialog';
import { useQuery } from 'react-query';
import { Claim } from '../../services/ClaimService';
import dayjs from 'dayjs';
import RangeCalendar from '../../components/Common/RangeCalendar';
import { useRecoilValue, useSetRecoilState, useResetRecoilState } from 'recoil';
import {
  myInfoIds,
  searchSaveState,
  shopListState,
  touchUIState,
} from '../../recoil/atoms';
import { useHistory } from 'react-router-dom';
import { BlockUI } from 'primereact/blockui';
import { CommonUtil } from '../../utils/commonUtil';
import SendSMS from './SendSMS.jsx';
import { ServiceProvider } from '../../services/index';
import { Checkbox } from 'primereact/checkbox';
import { Tooltip } from 'primereact/tooltip';
import { claimSearchState } from '../../recoil/atoms';

const partnerService = ServiceProvider.partner;

const ClaimList = () => {
  const history = useHistory();
  const touchUI = useRecoilValue(touchUIState);
  const [showReportMoal, setShowReportModal] = useState(false);
  const [showSmsMoal, setShowSmsModal] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const today = dayjs();
  const startDate = dayjs('20240701').toDate();
  const endDate = today;
  const [lazyState, setlazyState] = useState({
    first: 0,
    rows: 100,
    page: 0,
  });

  const myInfo = useRecoilValue(myInfoIds);
  const partner = myInfo.myRoleCode === 'D_ADM';
  const aid = myInfo.myAssociationId;

  const [customerInfo, setCustomerInfo] = useState({
    carNbr: '',
    carVIN: '',
    newCarNbr: '',
    customerName: '',
    customerContact: '',
    association: { value: '' },
    warranty: { value: '' },
    addType: { value: '' },
    receiptDate: null,
  });

  const [carDetailData, setCarDetailData] = useState(null);

  const [loading, setLoading] = useState(false);
  const [searchLoading, setSearchLoading] = useState(false);
  const defaultData = {
    startDate: dayjs(startDate).format('YYYYMMDD'),
    endDate: dayjs(endDate).format('YYYYMMDD'),
    page: lazyState.page + 1,
    size: lazyState.rows,
    orderType: 'D',
  };

  const [searchMode, setSearchMode] = useState(false);
  const [searchTotal, setSearchTotal] = useState(0);

  const { data, isLoading } = useQuery({
    queryKey: ['list', searchMode, lazyState.page, lazyState.rows],
    queryFn: () => Claim.getList(defaultData),
    enabled: !searchMode && !!aid,
    refetchInterval: 30000, // 30초 간격
  });

  const setSearchState = useSetRecoilState(claimSearchState);
  const searchState = useRecoilValue(claimSearchState);
  const resetSearchState = useResetRecoilState(claimSearchState);
  const setSearchSaveState = useSetRecoilState(searchSaveState);
  const isSearchSave = useRecoilValue(searchSaveState);
  const resetSearchSaveState = useResetRecoilState(searchSaveState);

  const getSearchClaimList = async () => {
    try {
      setSearchLoading(true);
      const data = await Claim.getList({
        ...searchState,
        customerContact: searchState.customerContact?.replaceAll('-', ''),
        startDate: dayjs(searchState.startDate).format('YYYYMMDD'),
        endDate: dayjs(searchState.endDate).format('YYYYMMDD'),
        page: lazyState.page + 1,
        size: lazyState.rows,
      });
      if (data) {
        setTableData(data.list);
        setSearchTotal(data.total);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setSearchLoading(false);
    }
  };

  useEffect(() => {
    if (isSearchSave || searchMode) {
      getSearchClaimList();
      setSearchMode(true);
    }
  }, [lazyState.page, searchMode, isSearchSave]);

  useEffect(() => {
    if (data && !isLoading && !searchMode && !isSearchSave) {
      setTableData(data.list);
      setSearchMode(false);
    }
  }, [data, isLoading, searchMode, isSearchSave]);

  const shopList = useRecoilValue(shopListState);

  const handleCustomerInfoInput = (event) => {
    const target = event.target;
    const { name, value } = target;
    setCustomerInfo({ ...customerInfo, [name]: value });
  };

  const handleSearchInput = (event) => {
    const target = event.target;
    const { name, value } = target;
    setSearchState({ ...searchState, [name]: value });
  };

  const resetSearch = () => {
    setSearchMode(false);
    setTodaySearch(false);
    setTodaySearchList([]);
    setTodayUpdateSearch(false);
    setTodayUpdateList([]);
    setSearchTotal(0);
    resetSearchState();
    resetSearchSaveState();
  };

  const checkWarranty = async () => {
    const carNum = customerInfo.newCarNbr
      ? customerInfo.newCarNbr
      : customerInfo.carNbr;

    setLoading(true);
    if (!!carNum) {
      try {
        const list = await Claim.getList({
          startDate: '20230101',
          endDate: dayjs(new Date()).format('YYYYMMDD'),
          carNbr: carNum,
          page: 1,
          size: 99,
        });

        if (!!list.list.length) {
          setLoading(false);
          return window.cerp.toast.warn(
            '등록완료 건',
            `이미 등록된 차량입니다.`
          );
        }
      } catch (error) {
        console.log(error);
      }

      try {
        const data = await Claim.ClaimPerfomanceCheck(
          carNum,
          null,
          customerInfo.carVIN ? customerInfo.carVIN : null
        );
        if (!!data.length) {
          setLoading(false);
          setCustomerInfo({
            ...customerInfo,
            association: { value: 'association' },
            warranty: {
              value: data[0].assuranceType === '1' ? 'warranty_s' : 'warranty',
            },
          });
          setCarDetailData(data[0]);
        } else {
          setLoading(false);
          window.cerp.toast.warn('보증 불가');
          setCustomerInfo({
            ...customerInfo,
            association: { value: 'non_association' },
          });
        }
      } catch (error) {
        setLoading(false);
        window.cerp.toast.warn(
          '보증 불가',
          `${error?.response.data.error?.message}`
        );
        setCustomerInfo({
          ...customerInfo,
          association: { value: 'non_association' },
        });
      }
    } else if (!carNum) {
      setLoading(false);
      return window.cerp.toast.warn('차량번호는 필수입니다.');
    }
  };

  useEffect(() => {
    if (customerInfo.carNbr === '') {
      setCustomerInfo({
        ...customerInfo,
        association: { value: '' },
        warranty: { value: '' },
      });
    }
  }, [customerInfo.carNbr]);

  const [statusList, setStatusList] = useState([]);
  const getStatusList = async () => {
    try {
      const data = await Claim.getDocsType('Z');
      if (data) {
        setStatusList(data.data);
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getStatusList();
  }, []);

  const [smsData, setSmsData] = useState({
    msgType: '',
    sourceAddr: '15332729',
    destAddr: '',
    subject: null, //opt,
    contents: '',
    fileData: null, //opt
  });

  useEffect(() => {
    if (customerInfo.customerName && customerInfo.customerContact) {
      setSmsData({ ...smsData, destAddr: customerInfo.customerContact });
    }
  }, [customerInfo]);

  const postSms = async () => {
    setLoading(true);
    const type = 'lms';

    const postData = {
      msgType: type,
      sourceAddr: smsData.sourceAddr,
      destAddr: smsData.destAddr.replaceAll('-', ''),
      subject: null, //opt,
      contents: smsData.contents,
      msgSubType: null,
      fileData: null, //opt
      url: smsData.url ?? null, // opt
      msgAuthType: 'SR',
      claimDataId: null,
    };
    // return console.log(postData);
    try {
      const data = await Claim.postSMS(postData);

      if (data) {
        window.cerp.toast.info('메세지 전송이 완료되었습니다.');
        setShowSmsModal(false);
      }
    } catch (error) {
      setShowSmsModal(false);
      console.log(error);
      window.cerp.toast.warn(error?.response?.data.error?.message);
    }
    setLoading(false);
  };

  const [partnerAllList, setPartnerAllList] = useState([]);
  const getPartnerList = async (aid) => {
    try {
      const data = await partnerService.list({
        aids: aid,
        page: 1,
        size: 200,
        useYN: 'Y',
      });
      if (data) {
        setPartnerAllList(data.data.list);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (!!aid && !partner) {
      getPartnerList(aid);
    }
  }, [aid, partner]);

  const [todaySearch, setTodaySearch] = useState(false);
  const [todaySearchList, setTodaySearchList] = useState([]);
  const [todayUpdateSearch, setTodayUpdateSearch] = useState(false);
  const [todayUpdateList, setTodayUpdateList] = useState([]);

  useEffect(() => {
    const today = dayjs(new Date()).format('YYYYMMDD');
    const list = tableData.filter((el) => el.receiptDate.includes(today));
    if (!!todaySearch) {
      setTodaySearchList(list);
    } else {
      setTodaySearchList([]);
    }
  }, [todaySearch, tableData]);

  useEffect(() => {
    const today = dayjs(new Date()).format('YYYY-MM-DD');
    const list = tableData.filter((el) => el.modTime?.includes(today));
    if (!!todayUpdateSearch) {
      setTodayUpdateList(list);
    } else {
      setTodayUpdateList([]);
    }
  }, [tableData, todayUpdateSearch]);

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      setSearchMode(true);
      getSearchClaimList();
    }
  };

  const [exporting, setExporting] = useState(false);
  const exportToExcel = async () => {
    setExporting(true);

    const params = {
      ...searchState,
      shopId: !!searchState.shopId ? searchState.shopId : null,
      startDate: dayjs(searchState.startDate).format('YYYYMMDD'),
      endDate: dayjs(searchState.endDate).format('YYYYMMDD'),
      page: lazyState.page + 1,
      size: lazyState.rows,
    };

    await Claim.exportToExcel(params);
    setExporting(false);
  };

  const goToDetailPage = (value) => {
    const { dataId } = value;
    history.push({
      pathname: `/compensation/accident/claim/detail/${dataId}`,
    });
  };

  const possibleRepair = ['Z07', 'Z08', 'Z16', 'Z19', 'Z20'];
  const [repairPossible, setRepairPossible] = useState(false);
  // useEffect(() => {
  //   const isPossible = possibleRepair.find((el) => el === statusCode);
  //   if (isPossible) {
  //     setRepairPossible(true);
  //   } else {
  //     setRepairPossible(false);
  //   }
  // }, [statusCode, repairPossible]);

  return (
    <div>
      <BlockUI
        template={
          loading && (
            <i className="pi pi-spin pi-spinner text-white-alpha-90 font-bold text-6xl" />
          )
        }
      >
        {partner ? null : (
          <Panel className="shadow-1">
            <div className="flex justify-content-between align-items-center">
              <div className="font-bold">클레임 접수</div>
            </div>

            <div className="grid mt-2">
              <div className="md:col-7 sm:col">
                <Panel header="1. 보증 가능 여부 조회">
                  <div className="flex justify-content-center">
                    <div className="grid col-12 p-0">
                      <div className="md:col-3 col-6 font-bold">
                        차량번호
                        <InputText
                          value={customerInfo.carNbr}
                          onChange={(e) => handleCustomerInfoInput(e)}
                          className="w-full mt-2"
                          name="carNbr"
                          placeholder="차량번호 입력 후 엔터"
                          onKeyDown={(e) =>
                            e.key === 'Enter'
                              ? customerInfo.carNbr || customerInfo.carVIN
                                ? checkWarranty()
                                : window.cerp.toast.warn(
                                    '차량번호 또는 차대번호를 입력하세요'
                                  )
                              : null
                          }
                        />
                      </div>
                      <div className="md:col-3 col-6 font-bold">
                        <div className="flex font-bold align-items-center gap-2">
                          차량번호(변경)
                          <Tooltip target=".custom-target-icon" />
                          <i
                            className="custom-target-icon pi pi-question-circle p-text-secondary p-overlay-badge"
                            data-pr-tooltip="변경된번호로 조회하는 경우 입력"
                            data-pr-position="top"
                            style={{ cursor: 'pointer' }}
                          />
                        </div>
                        <InputText
                          value={customerInfo.newCarNbr}
                          onChange={(e) => handleCustomerInfoInput(e)}
                          className="w-full mt-2"
                          name="newCarNbr"
                          placeholder="변경된 차량번호 입력"
                          onKeyDown={(e) =>
                            e.key === 'Enter'
                              ? customerInfo.newCarNbr || customerInfo.carNbr
                                ? checkWarranty()
                                : window.cerp.toast.warn(
                                    '차량번호 또는 차대번호를 입력하세요'
                                  )
                              : null
                          }
                        />
                      </div>
                      <div className="md:col-4 col-6 font-bold">
                        차대번호
                        <InputText
                          placeholder="차대번호 17자리 입력"
                          value={customerInfo.carVIN}
                          onChange={(e) => handleCustomerInfoInput(e)}
                          className=" w-full mt-2"
                          name="carVIN"
                        />
                      </div>
                      <div className="md:col-2 col-4 p-0 md:ml-0 ml-2">
                        <div className="flex align-items-end h-full pb-2">
                          <Button
                            type="button"
                            label="조회"
                            icon="pi pi-search"
                            onClick={() =>
                              customerInfo.carNbr || customerInfo.carVIN
                                ? checkWarranty(
                                    customerInfo.carNbr,
                                    customerInfo.carVIN
                                  )
                                : window.cerp.toast.warn(
                                    '차량번호 또는 차대번호를 입력하세요'
                                  )
                            }
                            className="w-full  mt-1"
                            loading={loading}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <Divider />
                  <div className="p-1">
                    <div className="field grid align-items-center">
                      <div className="col-2 font-bold ">보증가능</div>
                      <div className="col-7">
                        <div className="flex justify-content-between">
                          <div className="flex align-items-center gap-2">
                            <RadioButton
                              inputId="association"
                              name="association"
                              checked={
                                customerInfo.association.value === 'association'
                              }
                            />
                            <label htmlFor="association">SR 진단차량</label>
                            <RadioButton
                              inputId="non_association"
                              name="non_association"
                              checked={
                                customerInfo.association.value ===
                                'non_association'
                              }
                            />
                            <label htmlFor="non_association">미 진단차량</label>
                          </div>
                        </div>
                      </div>
                      {customerInfo.association.value === 'non_association' && (
                        <div className="col text-red-600">보증불가</div>
                      )}
                    </div>
                    <div className="field grid align-items-center mb-0">
                      <div className="col-2 font-bold">보증구분</div>
                      <div className="col-7">
                        <div className="flex justify-content-between">
                          <div className="flex align-items-center gap-2">
                            <RadioButton
                              inputId="warranty"
                              name="warranty"
                              value="warranty"
                              checked={
                                customerInfo.warranty.value === 'warranty'
                              }
                            />
                            <label htmlFor="warranty">보험사보증</label>
                            <RadioButton
                              inputId="warranty_s"
                              name="warranty_s"
                              value="warranty_s"
                              checked={
                                customerInfo.warranty.value === 'warranty_s'
                              }
                            />
                            <label htmlFor="warranty_s">자가보증</label>
                          </div>
                        </div>
                      </div>
                      {customerInfo.warranty.value === 'warranty_s' && (
                        <div className="col text-red-600">보증불가</div>
                      )}
                    </div>
                  </div>
                </Panel>
              </div>
              <div className="md:col-5 sm:col">
                <Panel header="2. 기본 정보">
                  <div className="flex justify-content-center">
                    <div className="grid col-12 p-0">
                      <div className="col-4 font-bold">
                        고객명
                        <InputText
                          value={customerInfo.customerName}
                          onChange={(e) => handleCustomerInfoInput(e)}
                          className=" w-full mt-2"
                          name="customerName"
                          placeholder="(필수) 고객명 입력"
                        />
                      </div>

                      <div className="col-5 font-bold">
                        고객연락처
                        <InputText
                          value={CommonUtil.Formatter.phone(
                            customerInfo.customerContact
                          )}
                          onChange={(e) => handleCustomerInfoInput(e)}
                          className=" w-full mt-2"
                          name="customerContact"
                          placeholder="(필수) 연락처 입력"
                        />
                      </div>
                      <div className="col-3 p-0">
                        <div className="flex align-items-end h-full pb-2">
                          <Button
                            type="button"
                            label="SMS 전송"
                            icon="pi pi-envelope"
                            onClick={
                              customerInfo.customerName &&
                              customerInfo.customerContact
                                ? () => {
                                    setShowSmsModal(true);
                                  }
                                : () =>
                                    window.cerp.toast.warn(
                                      '고객명과 연락처를 입력하세요.'
                                    )
                            }
                            className="w-full mt-1"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </Panel>

                <div
                  className="flex justify-content-end align-items-end gap-2"
                  style={{ height: '60px' }}
                >
                  <Button
                    type="button"
                    label="정보 초기화"
                    icon="pi pi-replay"
                    className="p-button-outlined"
                    onClick={() =>
                      setCustomerInfo({
                        carNum: '',
                        carVIN: '',
                        newCarNbr: '',
                        customerName: '',
                        customerContact: '',
                        association: { value: '' },
                        warranty: { value: '' },
                      })
                    }
                  />
                  <Button
                    type="button"
                    label="수기입력"
                    icon="pi pi-pencil"
                    className="p-button-outlined"
                    onClick={() =>
                      !!customerInfo.carNbr
                        ? setShowConfirmModal(true)
                        : window.cerp.toast.warn('차량번호를 입력해 주세요.')
                    }
                  />
                </div>
              </div>
            </div>
          </Panel>
        )}
      </BlockUI>
      {/* {partner ? null : ( */}
      <Panel className="shadow-1 mt-3">
        <div className="grid">
          <div className="col-12 md:col-8 lg:col-2">
            <div className="mb-2 font-bold">업무처리상태</div>
            <span onKeyDown={handleKeyDown}>
              <Dropdown
                value={searchState.checkType}
                onChange={(e) => {
                  setSearchState((ps) => ({
                    ...ps,
                    checkType: e.target.value,
                  }));
                }}
                options={statusList}
                optionLabel="label"
                placeholder="상태 선택"
                className="w-full"
              />
            </span>
          </div>
          <div className="col-12 md:col-8 lg:col-4">
            <div className="mb-2 font-bold">기간</div>
            <RangeCalendar
              startDate={searchState.startDate}
              endDate={searchState.endDate}
              onStartDateChanged={(date) => {
                setSearchState((ps) => ({
                  ...ps,
                  startDate: date,
                }));
              }}
              onEndDateChanged={(date) => {
                setSearchState((ps) => ({
                  ...ps,
                  endDate: date,
                }));
              }}
              touchUI={touchUI}
              showNavigators={true}
            />
          </div>
          {partner ? null : (
            <>
              <div className="col-12 md:col-8 lg:col-2">
                <div className="mb-2 font-bold">성능점검장</div>
                <span onKeyDown={handleKeyDown}>
                  <Dropdown
                    value={searchState.shopId}
                    onChange={(e) => {
                      setSearchState((ps) => ({
                        ...ps,
                        shopId: e.target.value,
                      }));
                    }}
                    options={shopList}
                    optionLabel="label"
                    placeholder="점검장 선택"
                    className="w-full"
                  />
                </span>
              </div>
              <div className="col-12 md:col-8 lg:col-2">
                <div className="mb-2 font-bold">접수일자 기준</div>
                <span onKeyDown={handleKeyDown}>
                  <Dropdown
                    value={searchState.orderType}
                    onChange={(e) => {
                      setSearchState((ps) => ({
                        ...ps,
                        orderType: e.target.value,
                      }));
                    }}
                    options={[
                      { value: 'D', label: '내림차순' },
                      { value: 'A', label: '오름차순' },
                    ]}
                    optionLabel="label"
                    placeholder="정렬순서 선택"
                    className="w-full"
                  />
                </span>
              </div>
            </>
          )}
        </div>

        <div className="grid mt-2">
          <div className="col-6 md:col-2 col">
            <div className="mb-2 font-bold">이름</div>
            <InputText
              value={searchState.customerName ?? ''}
              onChange={(e) => handleSearchInput(e)}
              onKeyDown={handleKeyDown}
              className="w-full"
              name={'customerName'}
              placeholder={'고객명 입력'}
            />
          </div>
          <div className="col-6 md:col-2 col">
            <div className="mb-2 font-bold">연락처</div>
            <InputText
              value={searchState.customerContact ?? ''}
              onChange={(e) => handleSearchInput(e)}
              onKeyDown={handleKeyDown}
              className="w-full"
              name={'customerContact'}
              placeholder={'고객 연락처 입력'}
            />
          </div>
          <div className="col-6 md:col-2 col">
            <div className="mb-2 font-bold">차량번호</div>
            <InputText
              value={searchState.carNbr ?? ''}
              onChange={(e) => handleSearchInput(e)}
              onKeyDown={handleKeyDown}
              className="w-full"
              name="carNbr"
              placeholder="차량번호 입력"
            />
          </div>
          {partner ? null : (
            <>
              <div className="col-6 md:col-2 col">
                <div className="mb-2 font-bold">최초 작성자</div>
                <InputText
                  value={searchState.receiptName ?? ''}
                  onChange={(e) => handleSearchInput(e)}
                  onKeyDown={handleKeyDown}
                  className="w-full"
                  name="receiptName"
                  placeholder="최초 작성자 입력"
                />
              </div>
              <div className="col-6 md:col-2 col">
                <div className="mb-2 font-bold">최종 작성자</div>
                <InputText
                  value={searchState.completeName ?? ''}
                  onChange={(e) => handleSearchInput(e)}
                  onKeyDown={handleKeyDown}
                  className="w-full"
                  name="completeName"
                  placeholder="최종 작성자 입력"
                />
              </div>
            </>
          )}
        </div>
        <div className="flex gap-4 mt-3">
          <Divider />
        </div>
        <div className="flex justify-content-between md:flex-row flex-column">
          <div className="flex gap-4">
            <div className="flex align-items-center">
              <Checkbox
                inputId="today"
                onChange={(e) => setTodaySearch(e.checked)}
                checked={todaySearch}
                disabled={todayUpdateSearch}
              />
              <label className="ml-2" htmlFor="today">
                당일 접수건 조회
              </label>
            </div>
            <div className="flex align-items-center">
              <Checkbox
                inputId="update"
                onChange={(e) => setTodayUpdateSearch(e.checked)}
                checked={todayUpdateSearch}
                disabled={todaySearch}
              />
              <label className="ml-2" htmlFor="update">
                당일 업데이트건 조회
              </label>
            </div>
            <div className="flex align-items-center">
              <Checkbox
                inputId="searchSave"
                onChange={(e) => setSearchSaveState(e.checked)}
                checked={isSearchSave}
                // disabled={todaySearch}
              />
              <label className="ml-2" htmlFor="searchSave">
                검색조건 기억하기
              </label>
            </div>
          </div>
          <div className="flex justify-content-end md:mt-0 mt-2">
            {partner ? null : (
              <Button
                label="엑셀 다운로드"
                className="p-button-outlined mr-1"
                icon="pi pi-download"
                disabled={exporting || tableData.length === 0}
                loading={exporting}
                onClick={async () => await exportToExcel()}
              />
            )}
            <Button
              label="검색조건 초기화"
              icon="pi pi-undo"
              className="p-button-outlined mr-1"
              onClick={resetSearch}
            />
            <Button
              label="검색"
              icon="pi pi-search"
              onClick={() => {
                setSearchMode(true);
                getSearchClaimList();
              }}
              loading={searchLoading}
            />
          </div>
        </div>
      </Panel>

      <Panel className="shadow-1 mt-3 mb-3">
        <DataTable
          value={
            todaySearch
              ? todaySearchList
              : todayUpdateSearch
              ? todayUpdateList
              : tableData
          }
          lazy
          onPage={(e) => setlazyState(e)}
          rows={lazyState.rows}
          first={lazyState.first}
          totalRecords={
            todaySearch
              ? todaySearchList.length
              : todayUpdateSearch
              ? todayUpdateList.length
              : searchMode
              ? searchTotal
              : !!data?.total
              ? data?.total
              : 0
          }
          paginator
          responsiveLayout="scroll"
          paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
          currentPageReportTemplate={
            todaySearch || todayUpdateSearch || searchMode || isSearchSave
              ? '전체 {totalRecords}건'
              : '전체 {totalRecords}건 중 {first} ~ {last}번'
          }
          rowsPerPageOptions={[20, 30, 50, 100, 500, 1000, 5000, 10000]}
          showGridlines
          emptyMessage="데이터가 없습니다."
          loading={(isLoading && !loading) || !statusList.length}
          onSelectionChange={({ value }) => {
            goToDetailPage(value);
          }}
          dataKey="id"
          selection={selectedRows}
          selectionMode="single"
          stripedRows
        >
          <Column
            align={'center'}
            field="dtn"
            header="순번"
            body={(dataId, column) => (
              <div className="w-2rem">{column.rowIndex + 1}</div>
            )}
          />

          <Column
            align={''}
            field={'claimStatus'}
            header={'업무처리상태'}
            body={({ claimStatus }) => (
              <div className="w-14rem">
                {statusList.find((el) => el.value === claimStatus)?.label}
              </div>
            )}
          />
          <Column
            align={'center'}
            field={'receiptDate'}
            header={'접수일자'}
            body={({ receiptDate }) => (
              <div className="w-8rem">
                {CommonUtil.Formatter.stringToDayForm(receiptDate)}
              </div>
            )}
          />
          {partner ? null : (
            <Column
              align={'center'}
              field={'shopId'}
              header={'성능점검장'}
              body={({ shopId }) => {
                const item = shopList.find((el) => el.value === shopId);
                return (
                  <div className="w-8rem">{item?.label?.split(']')[1]}</div>
                );
              }}
            />
          )}
          {TABLE_COLUMNS.map((col, i) => (
            <Column
              align={'center'}
              key={i}
              field={col.field}
              header={<div className="w-10rem">{col.header}</div>}
            />
          ))}
          {partner ? null : (
            <Column
              align={'center'}
              field={'customerContact'}
              header={'고객연락처'}
              className="w-10rem"
              body={({ customerContact }) => (
                <div className="w-10rem text-center">
                  {CommonUtil.Formatter.phone(customerContact)}
                </div>
              )}
            />
          )}
          <Column
            align={'center'}
            field={'carAddress'}
            header={'고객차량위치'}
            className="w-22rem"
            body={({ carAddress }) => (
              <div className="w-22rem text-center ">{carAddress}</div>
            )}
          />
          <Column
            align={'center'}
            field={'note'}
            header={'접수증상'}
            className="w-22rem"
            body={({ note }) => (
              <div className="w-22rem text-center">{note}</div>
            )}
          />
          {partner ? null : (
            <Column
              align={'center'}
              field={'partnerId'}
              header={'진단점'}
              body={({ partnerInfo }) => {
                const ids = [];
                partnerInfo.map((el) => ids.push(el.partnerId));
                const data = [];
                ids &&
                  ids.forEach((id) =>
                    data.push(
                      partnerAllList.filter((el) => id === el.partnerId)
                    )
                  );
                return (
                  <div
                    className="w-10rem text-center"
                    style={{ whiteSpace: 'pre-wrap' }}
                  >
                    {data
                      ?.flat()
                      .map(
                        (el, idx) =>
                          `${el.partnerName}${
                            data.length - 1 === idx ? '' : '\r\n'
                          }`
                      )}
                  </div>
                );
              }}
            />
          )}
          {partner ? null : (
            <Column
              align={'center'}
              field={'partnerId'}
              header={'진단점 연락처'}
              body={({ partnerInfo }) => {
                const ids = [];
                partnerInfo.map((el) => ids.push(el.partnerId));
                const data = [];
                ids &&
                  ids.forEach((id) =>
                    data.push(
                      partnerAllList.filter((el) => Number(id) === el.partnerId)
                    )
                  );
                return (
                  <div className="w-9rem text-center">
                    {data?.flat().map((el, idx) => {
                      return `${CommonUtil.Formatter.cellphone(el.cellphone)}${
                        data.length - 1 === idx ? '' : '\r\n'
                      }`;
                    })}
                  </div>
                );
              }}
            />
          )}
          {partner ? null : (
            <Column
              align={'center'}
              field={'receipName'}
              header={'최초 작성자'}
              body={({ receiptName }) => (
                <div className="w-6rem text-center">{receiptName}</div>
              )}
            />
          )}
          {partner ? null : (
            <Column
              align={'center'}
              field={'completeName'}
              header={'최종 작성자'}
              body={({ completeName }) => (
                <div className="w-6rem text-center">{completeName}</div>
              )}
            />
          )}

          <Column
            align={'center'}
            field={'insurancePaymentAmt'}
            header={'총지급액'}
            className="w-8rem"
            body={({ insurancePaymentAmt, claimStatus }) => {
              const isPossible = possibleRepair.find(
                (el) => el === claimStatus
              );

              return (
                <div className="w-8rem text-center">
                  {isPossible && !!insurancePaymentAmt
                    ? insurancePaymentAmt
                        ?.toString()
                        ?.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                        ?.toString() + ' 원'
                    : '-'}
                </div>
              );
            }}
          />
        </DataTable>
      </Panel>

      {showSmsMoal && (
        <SendSMS
          showSmsType="APP_DOWNLOAD"
          smsData={smsData}
          setSmsData={setSmsData}
          customerInfo={customerInfo}
          onHide={() => setShowSmsModal(!showSmsMoal)}
          postSms={postSms}
        />
      )}
      {showReportMoal && (
        <ReportDialog onHide={() => setShowReportModal(!showReportMoal)} />
      )}

      {showConfirmModal && (
        <Modal.Default
          onHide={() => setShowConfirmModal(false)}
          header={'수기입력 등록'}
          widthClass="30rem"
        >
          <div className="pb-4">
            고객정보를 다시 한번 확인해주세요.
            <br />
            <Divider />
            <strong className="text-lg">
              - 차량번호 : {customerInfo.carNbr}
            </strong>
          </div>
          <div className="flex gap-4 justify-content-center	">
            <Button
              label="취소"
              className="p-button-danger p-button-outlined"
              onClick={() => setShowConfirmModal(false)}
            />
            <Button
              label="등록"
              className=" "
              onClick={() => {
                history.push({
                  pathname: '/compensation/accident/claim/detail/new',
                  state: { customerInfo, carDetailData },
                });
              }}
            />
          </div>
        </Modal.Default>
      )}
    </div>
  );
};

export default ClaimList;

const TABLE_COLUMNS = [
  { field: 'carNbr', header: '차량번호' },
  { field: 'newCarNbr', header: '차량번호(변경)' },
  { field: 'vin', header: '차대번호' },
  { field: 'carName', header: '차명' },
  { field: 'customerName', header: '고객명' },
];
