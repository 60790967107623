import React from 'react';
import { Galleria } from 'primereact/galleria';
import ReactPanZoom from 'react-image-pan-zoom-rotate';
const images = JSON.parse(sessionStorage.getItem('images'));

const url = process.env.REACT_APP_S3_BASE_URL;
const ImagesView = () => {
  const itemTemplate = (item) => {
    return (
      <div style={{ display: 'block', height: '90vh' }}>
        <div>
          <ul className="font-bold my-4">
            <li>
              파일명 : {item.file ? item.file.name : item.originalFileName}
            </li>
            <li> 등록일시 : {item.regTime?.replace('T', ' ')}</li>
            {item.modTime ? (
              <li>수정일시 : {item.modTime?.replace('T', ' ')}</li>
            ) : null}
          </ul>
        </div>
        {item.originalFileName.includes('pdf') ? (
          <div className="flex justify-content-center">
            <span className="border-1 border-primary border-round font-bold p-2">
              <a href={url + item.filePath}>
                <span className="pi pi-download mr-2"></span>
                다운로드
              </a>
            </span>
          </div>
        ) : (
          <ReactPanZoom
            image={item.file ? item.file.preview : url + item.filePath}
            alt={item.file ? item.file.name : item.originalFileName}
          />
        )}
      </div>
    );
  };

  const thumbnailTemplate = (item) => {
    return (
      <img
        src={item.file ? item.file.preview : url + item.filePath}
        alt={item.file ? item.file.name : item.originalFileName}
        style={{
          display: 'block',
          height: '50px',
        }}
      />
    );
  };

  const handleKeyDown = (event) => {
    const nextButton = document.querySelector(
      '.p-galleria-item-next.p-galleria-item-nav.p-link'
    );
    const prevButton = document.querySelector(
      '.p-galleria-item-prev.p-galleria-item-nav.p-link'
    );

    if (event.key === 'ArrowRight') {
      nextButton.click();
    } else if (event.key === 'ArrowLeft') {
      prevButton.click();
    }
  };

  return (
    <>
      <div className="pcr_wrapper" onKeyDown={(e) => handleKeyDown(e)}>
        <Galleria
          value={images}
          showItemNavigators
          circular
          numVisible={10}
          thumbnailsPosition="top"
          item={itemTemplate}
          thumbnail={thumbnailTemplate}
        />
      </div>
    </>
  );
};

export default ImagesView;
