import { Button } from 'primereact/button';
import { CommonUtil } from '../../utils/commonUtil';
import { InputText } from 'primereact/inputtext';
import React from 'react';
import { ServiceProvider } from '../../services';
import { classNames } from 'primereact/utils';

const validateService = {
  associationCode: ServiceProvider.association,
  enterpriseCode: ServiceProvider.enterprise,
  shopCode: ServiceProvider.shop,
  partnerCode: ServiceProvider.partner,
  loginId: ServiceProvider.user,
};

const ValidateInputText = ({
  field,
  fieldState,
  setValue,
  trigger,
  getValues,
  isAvailable,
  setIsAvailable,
  inputConfig,
  inputLabel,
  isModified,
  isModifiable,
}) => {
  async function getCodeAvailable(fieldName, inputData, userInfo) {
    // 카테고리에 따라서 중복검사 보내기
    //* type에 따라서 field명 재 가공해서 service provider로 바로 보내는 방법은?
    const { data: codeAvailable, error } = await validateService[
      fieldName
    ].checkAvailable(inputData, getValues());

    return { codeAvailable, error };
  }

  const onChangeInputValue = (e, fieldName) => {
    field.onChange(e);

    let value = e.target.value;
    value = CommonUtil.Formatter[fieldName](value);

    setValue(fieldName, value);
    setIsAvailable((ps) => ({
      ...ps,
      [fieldName]: false,
    }));
  };

  async function onClickHandler(e, fieldData = '', fieldName) {
    const isValid = await trigger(fieldName);

    if (fieldData === '' || !isValid) {
      return false;
    }

    try {
      const { codeAvailable } = await getCodeAvailable(
        fieldName,
        fieldData,
        getValues()
      );

      setIsAvailable((ps) => ({
        ...ps,
        [fieldName]: codeAvailable,
      }));

      if (codeAvailable) {
        window.cerp.toast.success(
          `${inputLabel} 확인 완료`,
          `사용할 수 있는 ${inputLabel} 입니다.`
        );
      } else {
        window.cerp.toast.warn(
          `${inputLabel} 확인 완료`,
          `이미 사용중인 ${inputLabel} 입니다.`
        );
      }
    } catch (error) {
      window.cerp.toast.error(
        `${inputLabel} 중복 확인 실패`,
        `[${error?.code}] ${error?.message}`
      );
    }
  }

  return (
    <div className="p-inputgroup" id={`input_${field.name}`}>
      <InputText
        id={field.name}
        {...inputConfig}
        {...field}
        onFocus={(e) => e.target.select()}
        onChange={(e) => onChangeInputValue(e, field.name)}
        className={classNames('', {
          'bg-gray-100': isModified && !isModifiable,
          'p-invalid': fieldState.error,
        })}
        readOnly={isModified && !isModifiable}
        autoComplete="off"
      />
      {(!isModified || isModifiable) && (
        <Button
          type="button"
          label="중복확인"
          disabled={isAvailable[field.name]}
          onClick={(e) => onClickHandler(e, getValues(field.name), field.name)}
        />
      )}
    </div>
  );
};

export default ValidateInputText;
