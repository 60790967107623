import React, { forwardRef } from 'react';

import { Password } from 'primereact/password';
import { classNames } from 'primereact/utils';

export const TitledPassword = forwardRef((props, ref) => {
  const {
    id,
    title = '',
    value,
    onChange,
    readOnly = false,
    // suffix = null,
    className = '',
    error = null,
    required = false,
    inputConfig,
  } = props;

  return (
    <div className="field m-0">
      <label htmlFor={`txt_${id}`}>
        {required && <i className="text-red-400 pi pi-check mr-1" />}
        {title}
      </label>
      <Password
        id={`txt_${id}`}
        inputRef={ref}
        className="w-full"
        inputClassName={classNames(
          'w-full ' + className,
          readOnly && (className === '' ? 'bg-gray-100' : className),
          { 'p-invalid': error }
        )}
        placeholder="영문, 숫자포함 4자리 이상"
        {...inputConfig}
        value={value}
        onChange={onChange}
        autoComplete="new-password"
      />
      <small id={`txt_err_${id}`} className="p-error">
        {error?.message}
      </small>
    </div>
  );
});
