import React, { useEffect, useState, useMemo, useCallback } from 'react';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { Panel } from 'primereact/panel';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Divider } from 'primereact/divider';
import { Button } from 'primereact/button';
import { useQuery } from 'react-query';
import { Claim } from '../../services/ClaimService';
import dayjs from 'dayjs';
import RangeCalendar from '../../components/Common/RangeCalendar';
import {
  useRecoilValue,
  useRecoilValueLoadable,
  useSetRecoilState,
  useResetRecoilState,
} from 'recoil';
import { myInfoSelector } from '../../recoil/selectors';
import {
  searchOldSaveState,
  shopListState,
  touchUIState,
} from '../../recoil/atoms';
import { useHistory } from 'react-router-dom';
import { CommonUtil } from '../../utils/commonUtil';
import { Checkbox } from 'primereact/checkbox';
import { claimOldSearchState } from '../../recoil/atoms';
import { Shop } from '../../services/CodeService.js';
import { Card } from 'primereact/card';

const ClaimOldList = () => {
  const history = useHistory();
  const touchUI = useRecoilValue(touchUIState);
  const [tableData, setTableData] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const today = dayjs();
  const startDate = dayjs('20240701').toDate();
  const endDate = today;
  const [lazyState, setlazyState] = useState({
    first: 0,
    rows: 100,
    page: 0,
  });
  const myInfoLoadable = useRecoilValueLoadable(myInfoSelector);
  const partner = myInfoLoadable.contents.roleCode === 'D_ADM';
  const aid = myInfoLoadable.contents.associationInfo?.associationId;

  const [customerInfo, setCustomerInfo] = useState({
    carNbr: '',
    carVIN: '',
    newCarNbr: '',
    customerName: '',
    customerContact: '',
    association: { value: '' },
    warranty: { value: '' },
    addType: { value: '' },
    receiptDate: null,
  });

  const [searchLoading, setSearchLoading] = useState(false);
  const defaultData = {
    startDate: dayjs(startDate).format('YYYYMMDD'),
    endDate: dayjs(endDate).format('YYYYMMDD'),
    page: lazyState.page + 1,
    size: lazyState.rows,
    orderType: 'D',
  };

  const [searchMode, setSearchMode] = useState(false);
  const [searchTotal, setSearchTotal] = useState(0);

  const { data, isLoading } = useQuery({
    queryKey: ['list', searchMode, lazyState.page, lazyState.rows],
    queryFn: () => Claim.getOldList(defaultData),
    enabled: !searchMode && !!aid,
    // refetchInterval: 30000, // 30초 간격
  });

  const setSearchState = useSetRecoilState(claimOldSearchState);
  const searchState = useRecoilValue(claimOldSearchState);
  const resetSearchState = useResetRecoilState(claimOldSearchState);
  const setSearchSaveState = useSetRecoilState(searchOldSaveState);
  const isSearchSave = useRecoilValue(searchOldSaveState);
  const resetSearchSaveState = useResetRecoilState(searchOldSaveState);

  const getSearchClaimList = useCallback(async () => {
    try {
      setSearchLoading(true);
      const data = await Claim.getOldList({
        ...searchState,
        customerContact: searchState.customerContact?.replaceAll('-', ''),
        startDate: dayjs(searchState.startDate).format('YYYYMMDD'),
        endDate: dayjs(searchState.endDate).format('YYYYMMDD'),
        page: lazyState.page + 1,
        size: lazyState.rows,
      });
      if (data) {
        setTableData(data.list);
        setSearchTotal(data.total);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setSearchLoading(false);
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    lazyState.page,
    lazyState.rows,
    searchState.customerContact,
    searchState.startDate,
    searchState.endDate,
  ]);

  useEffect(() => {
    if (isSearchSave || searchMode) {
      getSearchClaimList();
      setSearchMode(true);
    }
  }, [lazyState.page, searchMode, isSearchSave, getSearchClaimList]);

  useEffect(() => {
    if (data && !isLoading && !searchMode && !isSearchSave) {
      setTableData(data.list);
      setSearchMode(false);
    }
  }, [data, isLoading, searchMode, isSearchSave]);

  const setShopListState = useSetRecoilState(shopListState);
  const shopList = useRecoilValue(shopListState);

  useEffect(() => {
    if (!!myInfoLoadable.contents.associationInfo) {
      const getShopList = async (eids) => {
        try {
          const data = await Shop.getList({ eids: eids });
          if (data) {
            setShopListState(data);
          }
        } catch (error) {
          console.log(error);
        }
      };
      getShopList(myInfoLoadable.contents.associationInfo.associationId);
    }
  }, [myInfoLoadable.contents.associationInfo, setShopListState]);

  const handleSearchInput = (event) => {
    const target = event.target;
    const { name, value } = target;
    setSearchState({ ...searchState, [name]: value });
  };

  const resetSearch = () => {
    setSearchMode(false);
    setSearchTotal(0);
    resetSearchState();
    resetSearchSaveState();
  };

  useEffect(() => {
    if (customerInfo.carNbr === '') {
      setCustomerInfo((prev) => ({
        ...prev,
        association: { value: '' },
        warranty: { value: '' },
      }));
    }
  }, [customerInfo.carNbr]);

  const { data: status } = useQuery({
    queryKey: ['status'],
    queryFn: () => Claim.getDocsType('Z'),
    cacheTime: 86400000,
  });
  const statusList = useMemo(() => status?.data || [], [status]);

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      setSearchMode(true);
      getSearchClaimList();
    }
  };

  return (
    <div>
      <Card className="shadow-1">
        <div className="grid">
          <div className="col-12 md:col-8 lg:col-2">
            <div className="mb-2 font-bold">업무처리상태</div>
            <span onKeyDown={handleKeyDown}>
              <Dropdown
                value={searchState.checkType}
                onChange={(e) => {
                  setSearchState((ps) => ({
                    ...ps,
                    checkType: e.target.value,
                  }));
                }}
                options={statusList}
                optionLabel="label"
                placeholder="상태 선택"
                className="w-full"
              />
            </span>
          </div>
          <div className="col-12 md:col-8 lg:col-4">
            <div className="mb-2 font-bold">기간</div>
            <RangeCalendar
              startDate={searchState.startDate}
              endDate={searchState.endDate}
              onStartDateChanged={(date) => {
                setSearchState((ps) => ({
                  ...ps,
                  startDate: date,
                }));
              }}
              onEndDateChanged={(date) => {
                setSearchState((ps) => ({
                  ...ps,
                  endDate: date,
                }));
              }}
              touchUI={touchUI}
              showNavigators={true}
            />
          </div>
          <div className="col-12 md:col-8 lg:col-2">
            <div className="mb-2 font-bold">성능점검장</div>
            <span onKeyDown={handleKeyDown}>
              <Dropdown
                value={searchState.shopId}
                onChange={(e) => {
                  setSearchState((ps) => ({
                    ...ps,
                    shopId: e.target.value,
                  }));
                }}
                options={shopList}
                optionLabel="label"
                placeholder="점검장 선택"
                className="w-full"
              />
            </span>
          </div>
          <div className="col-12 md:col-8 lg:col-2">
            <div className="mb-2 font-bold">접수일자 기준</div>
            <span onKeyDown={handleKeyDown}>
              <Dropdown
                value={searchState.orderType}
                onChange={(e) => {
                  setSearchState((ps) => ({
                    ...ps,
                    orderType: e.target.value,
                  }));
                }}
                options={[
                  { value: 'D', label: '내림차순' },
                  { value: 'A', label: '오름차순' },
                ]}
                optionLabel="label"
                placeholder="정렬순서 선택"
                className="w-full"
              />
            </span>
          </div>
        </div>

        <div className="grid mt-2">
          <div className="col-6 md:col-2 col">
            <div className="mb-2 font-bold">이름</div>
            <InputText
              value={searchState.customerName ?? ''}
              onChange={(e) => handleSearchInput(e)}
              onKeyDown={handleKeyDown}
              className="w-full"
              name={'customerName'}
              placeholder={'고객명 입력'}
            />
          </div>
          <div className="col-6 md:col-2 col">
            <div className="mb-2 font-bold">연락처</div>
            <InputText
              value={searchState.customerContact ?? ''}
              onChange={(e) => handleSearchInput(e)}
              onKeyDown={handleKeyDown}
              className="w-full"
              name={'customerContact'}
              placeholder={'고객 연락처 입력'}
            />
          </div>
          <div className="col-6 md:col-2 col">
            <div className="mb-2 font-bold">차량번호</div>
            <InputText
              value={searchState.carNbr ?? ''}
              onChange={(e) => handleSearchInput(e)}
              onKeyDown={handleKeyDown}
              className="w-full"
              name="carNbr"
              placeholder="차량번호 입력"
            />
          </div>
          <div className="col-6 md:col-2 col">
            <div className="mb-2 font-bold">최초 작성자</div>
            <InputText
              value={searchState.receiptName ?? ''}
              onChange={(e) => handleSearchInput(e)}
              onKeyDown={handleKeyDown}
              className="w-full"
              name="receiptName"
              placeholder="최초 작성자 입력"
            />
          </div>
          <div className="col-6 md:col-2 col">
            <div className="mb-2 font-bold">최종 작성자</div>
            <InputText
              value={searchState.completeName ?? ''}
              onChange={(e) => handleSearchInput(e)}
              onKeyDown={handleKeyDown}
              className="w-full"
              name="completeName"
              placeholder="최종 작성자 입력"
            />
          </div>
        </div>
        <div className="flex gap-4 mt-3">
          <Divider />
        </div>
        <div className="flex justify-content-between md:flex-row flex-column">
          <div className="flex gap-4">
            <div className="flex align-items-center">
              <Checkbox
                inputId="searchSave"
                onChange={(e) => setSearchSaveState(e.checked)}
                checked={isSearchSave}
                // disabled={todaySearch}
              />
              <label className="ml-2" htmlFor="searchSave">
                검색조건 기억하기
              </label>
            </div>
          </div>
          <div className="flex justify-content-end md:mt-0 mt-2">
            <Button
              label="검색조건 초기화"
              icon="pi pi-undo"
              className="p-button-outlined mr-1"
              onClick={resetSearch}
            />
            <Button
              label="검색"
              icon="pi pi-search"
              onClick={() => {
                setSearchMode(true);
                getSearchClaimList();
              }}
              loading={searchLoading}
            />
          </div>
        </div>
      </Card>

      <Panel className="shadow-1 mt-3 mb-3">
        <DataTable
          value={tableData}
          lazy
          onPage={(e) => setlazyState(e)}
          rows={lazyState.rows}
          first={lazyState.first}
          totalRecords={
            searchMode ? searchTotal : !!data?.total ? data?.total : 0
          }
          paginator
          responsiveLayout="scroll"
          paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
          currentPageReportTemplate={
            searchMode || isSearchSave
              ? '전체 {totalRecords}건'
              : '전체 {totalRecords}건 중 {first} ~ {last}번'
          }
          rowsPerPageOptions={[20, 30, 50, 100, 500, 1000, 5000, 10000]}
          showGridlines
          emptyMessage="데이터가 없습니다."
          loading={isLoading}
          onSelectionChange={({ value }) => {
            setSelectedRows(value);
            history.push({
              pathname: `/compensation/accident/claim/detail/old/${value.dataId}`,
            });
          }}
          dataKey="id"
          selection={selectedRows}
          selectionMode="single"
          stripedRows
        >
          <Column
            align={'center'}
            field="dtn"
            header="순번"
            body={(dataId, column) => (
              <div className="w-2rem">{column.rowIndex + 1}</div>
            )}
          />

          <Column
            align={''}
            field={'claimStatus'}
            header={'업무처리상태'}
            body={({ claimStatus }) => (
              <div className="w-14rem">
                {claimStatus === 'Z1'
                  ? '고객접수중'
                  : statusList.find((el) => el.value === claimStatus)?.label}
              </div>
            )}
          />
          <Column
            align={'center'}
            field={'receiptDate'}
            header={'접수일자'}
            body={({ receiptDate }) => (
              <div className="w-8rem">
                {CommonUtil.Formatter.stringToDayForm(receiptDate)}
              </div>
            )}
          />
          {TABLE_COLUMNS.map((col, i) => (
            <Column
              align={'center'}
              key={i}
              field={col.field}
              header={<div className="w-10rem">{col.header}</div>}
            />
          ))}
          <Column
            align={'center'}
            field={'customerContact'}
            header={'고객연락처'}
            body={({ customerContact }) => (
              <div className="w-8rem">
                {CommonUtil.Formatter.phone(customerContact)}
              </div>
            )}
          />
          <Column
            align={'center'}
            field={'carAddress'}
            header={'고객차량위치'}
            body={({ carAddress }) => (
              <div className="w-8rem">{carAddress}</div>
            )}
          />
          <Column
            align={'center'}
            field={'note'}
            header={'접수증상'}
            body={({ note }) => <div className="w-18rem">{note}</div>}
          />
          {partner ? null : (
            <Column
              align={'center'}
              field={'receipName'}
              header={'최초 작성자'}
              body={({ receiptName }) => (
                <div className="w-6rem">{receiptName}</div>
              )}
            />
          )}
          {partner ? null : (
            <Column
              align={'center'}
              field={'completeName'}
              header={'최종 작성자'}
              body={({ completeName }) => (
                <div className="w-6rem">{completeName}</div>
              )}
            />
          )}
          {partner ? null : (
            <Column
              align={'center'}
              field={'insurancePaymentAmt'}
              header={'총지급액'}
              body={({ insurancePaymentAmt }) => (
                <div className="w-6rem">
                  {!!insurancePaymentAmt
                    ? insurancePaymentAmt
                        ?.toString()
                        ?.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                        ?.toString() + ' 원'
                    : '-'}
                </div>
              )}
            />
          )}
        </DataTable>
      </Panel>
    </div>
  );
};

export default ClaimOldList;

const TABLE_COLUMNS = [
  { field: 'carNbr', header: '차량번호' },
  { field: 'vin', header: '차대번호' },
  { field: 'carName', header: '차명' },
  { field: 'customerName', header: '고객명' },
];
