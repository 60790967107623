import React, { useState, useEffect, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import PartnerDocument from '../../components/Accident/PartnerDocument';
import Claim from '../../services/ClaimService';
import * as Button from '../../components/Atoms/Button';
import { InputText } from 'primereact/inputtext';
import { Panel } from 'primereact/panel';
import { CommonUtil } from '../../utils/commonUtil';
import { BlockUI } from 'primereact/blockui';
import CustomerDocument from '../../components/Accident/CustomerDocument';
import { useHistory } from 'react-router-dom';
import dayjs from 'dayjs';

const PartnerUpload = () => {
  const history = useHistory();
  let { aI, carNum, cusN, cusC, dealer, partner } = useParams();
  const aid = aI.split('=')[1];
  const carNbr = carNum.split('=')[1];
  const customerName = cusN.split('=')[1];
  const customerContact = cusC.split('=')[1]?.replaceAll('-', '');
  const dealerUser = dealer.split('=')[1] === 'true' ? true : false;
  const partnerUser = partner.split('=')[1] === 'true' ? true : false;

  const [selectedPartner, setSelectedPartner] = useState([]);
  const [fileItems, setFileItems] = useState([]); // 모든 사진 파일
  const [deleteImgs, setDeleteImgs] = useState([]); // 모든 삭제하는 사진 파일
  const [docs, setDocs] = useState([]); // claimDocs
  const [copyFile, setCopyFile] = useState([]); // 사진 파일 카피
  const [detailData, setDetailData] = useState({}); // claimData 기존고객
  const [isActive, setIsActive] = useState(true); // 사진 업로드하기 버튼 활성화 여부
  const [isCustomerUploadActive, setIsCustomerUploadActive] = useState(true); // 사진 업로드하기 버튼 활성화 여부
  const [statusCode, setStatusCode] = useState('');
  const [loading, setLoading] = useState(false);

  // 업로드 버튼 활성화
  const checkForChanges = useCallback(() => {
    if (fileItems.length === 0) {
      setIsActive(true);
      return;
    }

    const hasChangedState = fileItems.some(
      (file) => file.state === 'D' || file.state === 'I'
    );

    if (hasChangedState) {
      setIsActive(false);
      return;
    }

    return setIsActive(true);
  }, [fileItems]);

  useEffect(() => {
    checkForChanges();
  }, [fileItems, checkForChanges]);

  useEffect(() => {
    if (carNbr && customerName && customerContact) {
      const getDetailData = async () => {
        setLoading(true);
        try {
          const data = await Claim.getClaimDetail({
            aid,
            carNbr,
            customerName,
            customerContact,
          });
          if (data) {
            // console.log(data);
            setDetailData(data.claimData);
            setDocs(data.claimDocuments);
            setSelectedPartner(data.claimData.partnerInfo);
            setStatusCode(data.claimData.claimStatus);
          }

          setLoading(false);
        } catch (error) {
          console.log(error);
          setLoading(false);
          window.cerp.toast.error(
            '차량정보 조회실패',
            '다시 확인 후 재시도해주세요.'
          );
        }
      };
      getDetailData();
    }
  }, [carNbr, customerName, customerContact, dealerUser, aid]);

  const [isAllFile, setIsAllFile] = useState(false);

  useEffect(() => {
    if (!!fileItems.length) {
      const C_CONTRACT = fileItems?.find(
        (el) => el.documentCode === 'C_CONTRACT'
      );
      const C_DASHBOARD = fileItems?.find(
        (el) => el.documentCode === 'C_DASHBOARD'
      );
      const C_CERTIFICATE = fileItems?.find(
        (el) => el.documentCode === 'C_CERTIFICATE'
      );
      const C_CONSENT = fileItems?.find(
        (el) => el.documentCode === 'C_CONSENT'
      );
      const S_CHECK1 = fileItems?.find((el) => el.documentCode === 'S_CHECK1');
      const S_CHECK2 = fileItems?.find((el) => el.documentCode === 'S_CHECK2');
      const S_CONTRACT = fileItems?.find(
        (el) => el.documentCode === 'S_CONTRACT'
      );

      if (
        C_CONTRACT &&
        C_DASHBOARD &&
        C_CERTIFICATE &&
        C_CONSENT &&
        S_CHECK1 &&
        S_CHECK2 &&
        S_CONTRACT
      ) {
        setIsAllFile(true);
      } else {
        setIsAllFile(false);
      }
    }
  }, [fileItems]);

  //ANCHOR -  - 클레임 수정 PUT
  const changetClaim = async () => {
    setLoading(true);

    let fileDataList = [];
    let filesList = [];

    const del = [];
    if (!!deleteImgs.length) {
      const imgs = deleteImgs.filter((el) => !!el.imgId);

      if (!!imgs.length) {
        imgs.map((el) =>
          del.push({
            imgId: el.imgId,
            documentCode: el.documentCode,
            state: 'D',
          })
        );
      }

      fileDataList = del;
    }
    const findFile = fileItems.find((el) => el.file); // File 이 있는지 찾는다.
    const findImgId = fileItems.find((el) => el.imgId); // 기존 이미지를 찾는다.
    const idFile = []; // imgId 를 가지고 있는 기존 이미지파일
    fileItems.filter((el) => el.imgId && idFile.push(el));
    const imgFile = []; // file 을 가지고 있는 새로 등록한 이미지 파일
    fileItems.filter((el) => el.file && imgFile.push(el));

    if (findFile && !findImgId) {
      // console.log('새 이미지');
      fileItems.map((el) =>
        fileDataList.push({
          documentCode: el.documentCode,
          state: el.state,
        })
      );
      fileItems.map((el) => filesList.push(el.file));
    } else if (!findFile && findImgId) {
    } else if (findFile && findImgId) {
      imgFile.map((el, idx) =>
        fileDataList.push({
          documentCode: el.documentCode,
          state: el.state,
        })
      );
      imgFile.map((el) => filesList.push(el.file));
    }

    const partnerFiles = [];
    selectedPartner.map((partner) =>
      partner.partnerDocuments?.filter(
        (el) => el.file && partnerFiles.push(el.file)
      )
    );

    const modifyPartners = selectedPartner.map((partner) => {
      partner.partnerDocuments = partner.partnerDocuments
        ?.map((el) => {
          const { file, imgId, ...rest } = el;
          if (imgId) {
            return null;
          } else {
            return rest;
          }
        })
        .filter((el) => el !== null);
      return partner;
    });

    let partnerInfoData = [];
    if (!!modifyPartners) {
      partnerInfoData = modifyPartners.map((el) => ({
        partnerName: el?.partnerName?.split(' (')[0],
        carNbr: el.carNbr,
        statementNumber: el.statementNumber ?? null,
        checkDate: el.checkData ?? null,
        partnerId: el.partnerId,
        repairCode: el.repairCode?.toString(),
        reservationDate: !!el.reservationDate
          ? dayjs(el.reservationDate).format('YYYYMMDD')
          : null,
        useYn: el.useYn,
        documentGroupId: el?.documentGroupId ?? null,
        fileData: el.partnerDocuments ?? [],
        partnerType: !!el.partnerType ? el.partnerType?.toString() : null,
        claimStatusCode: el?.claimStatusCode ?? null,
        receiptDate: !!el.receiptDate
          ? dayjs(el.receiptDate).format('YYYYMMDD')
          : null,
        dueDate: el?.dueDate ? dayjs(el?.dueDate).format('YYYYMMDD') : null,
        paymentAmt: el?.paymentAmt ?? null,
        partnerShip: el.partnerShip ?? 'N',
      }));
    }

    const filterFile = fileItems.filter((el) => el.state !== 'L');
    const { fileData, files } =
      CommonUtil.File.convertForServerClaimPartner(filterFile);

    const updateData = {
      dataId: detailData?.dataId,
      associationId: aid,
      shopId: detailData.shopId ?? null,
      addType: detailData.addType,
      vin: detailData.vin ?? null,
      assuranceType: detailData?.assuranceType,
      checkFlag: detailData?.checkFlag ?? 'Y',
      funnelsType: detailData.funnelsType, // W or A
      claimStatus: !!isAllFile ? 'Z03' : statusCode ? statusCode : 'Z02',
      customerName: detailData.customerName,
      customerContact: detailData.customerContact,
      statementNumber: detailData.statementNumber ?? null,
      carNbr: detailData.carNbr,
      checkDate: detailData.checkDate ?? null,
      buyDate: detailData?.buyDate,
      checkDistance: detailData?.checkDistance, //vehicleInformation > TRVL_DSTNC
      distanceDriven: detailData.distanceDriven,
      vehicleValue: detailData.vehicleValue,
      producingCountry: detailData.producingCountry, // D or F
      carName: detailData.carName,
      carAddress: detailData?.carAddress,
      carLocation: detailData?.carLocation, //Opt
      diagnosisType: detailData?.diagnosisType, // 진단타입 B, D..
      consultationGroupId: detailData?.consultationGroupId ?? null,
      reservationDate: detailData.reservationDate ?? null, //Opt
      partnerInfo: !!partnerInfoData.length ? partnerInfoData : null, //Opt 진단점 목록
      receiptId: detailData.receiptId, // 최초 작성자
      receiptName: detailData.receiptName,
      receiptDate: detailData?.receiptDate, // 접수 일자
      documentGroupId: detailData?.documentGroupId, //Opt
      note: detailData.note ?? null, //Opt
      partenerReceiptDate: detailData?.partenerReceiptDate, //Opt 수리 입고일자
      maintenanceCode: detailData?.maintenanceCode,
      workYN: detailData.workYN, //Opt 수리여부
      workFee: detailData.workFee, //Opt 협의비용
      insuranceCode: detailData?.insuranceCode, // opt 보험코드
      insurancePolicyNumber: detailData?.insurancePolicyNumber, //opt 증권번호
      insurancePurchaseDate: detailData?.insurancePurchaseDate, //Opt 보험료 지급일
      insurancePaymentAmt: detailData?.insurancePaymentAmt, //Opt 보험료
      deductibleAmt: detailData?.deductibleAmt, // opt 자기부담금
      partnerNote: detailData?.partnerNote,
      repairNote: detailData?.repairNote,
      repairOpinion: detailData?.repairOpinion, // Opt 소견내용
      completeYN: detailData?.completeYN, //Opt 완료여부
      completeDate: detailData?.completeDate, //Opt 완료일
      completeName: detailData?.completeName,
      completeLoginId: detailData?.completeLoginId, //Opt 로그인유저 아이디
      completeLoginContact: detailData?.completeLoginContact, //opt 로그인유저 연락처
      fileData: fileData, //Otp
    };

    // return console.log(updateData, files, partnerFiles);
    try {
      const data = dealerUser
        ? await Claim.dealerUploadNoAuth(updateData, files, partnerFiles)
        : await Claim.partnerUploadNoAuth(updateData, files, partnerFiles);
      if (data) {
        setLoading(false);
        window.cerp.toast.success('업로드가 완료 되었습니다.');
        sessionStorage.removeItem('images');
        history.push({
          pathname: `/upload/claim/success`,
        });
      }
    } catch (error) {
      console.log(error);
      window.cerp.dialog.error(
        '사진 업로드 실패',
        `${error?.message ? error?.message : '잠시 후 다시 시도해주세요.'}`
      );
      setLoading(false);
    }
  };

  useEffect(() => {
    if (docs) {
      const updatedDocs = docs.map((item) => ({
        ...item,
        state: 'L',
      }));
      setFileItems(updatedDocs);
      setCopyFile(updatedDocs);
    }
  }, [docs]);

  const CommonCol = ({ title, name, value, readOnly = true }) => {
    return (
      <div className="field grid align-items-center">
        <div className="col-3 text-2xl">
          <label className="font-bold" htmlFor="">
            {title}
          </label>
        </div>
        <div className="col">
          <InputText
            readOnly={readOnly}
            value={value || ''}
            name={name}
            className=" w-full font-bold text-2xl"
          />
        </div>
      </div>
    );
  };

  return (
    <div className="p-4">
      <div className="flex justify-content-center">
        <h2 className="font-bold">
          {dealerUser
            ? '매매상사 사진 업로드'
            : partnerUser
            ? '차량 사진 업로드'
            : '진단점 차량 사진 업로드'}
        </h2>
      </div>
      <BlockUI
        fullScreen
        template={
          loading && (
            <i className="pi pi-spin pi-spinner text-white-alpha-90 font-bold text-6xl" />
          )
        }
        blocked={loading}
      >
        <Panel header="1. 차량 정보">
          <CommonCol title="차량번호" name="carNbr" value={carNbr} />
          <CommonCol
            title="차량명"
            name="carName"
            value={detailData?.carName}
          />
        </Panel>
        {dealerUser ? (
          <CustomerDocument
            type="upload"
            deleteImgs={deleteImgs}
            setDeleteImgs={setDeleteImgs}
            fileItems={fileItems}
            setFileItems={setFileItems}
            copyFile={copyFile}
            carNbr={carNbr}
            forDealer={true}
            setIsActive={setIsCustomerUploadActive}
          />
        ) : (
          partnerUser && (
            <PartnerDocument
              deleteImgs={deleteImgs}
              setDeleteImgs={setDeleteImgs}
              fileItems={fileItems}
              setFileItems={setFileItems}
              copyFile={copyFile}
              carNbr={carNbr}
              selectedPartner={selectedPartner}
              setSelectedPartner={setSelectedPartner}
              forPartner={true}
            />
          )
        )}

        <div className="flex justify-content-center my-5">
          <Button.Default
            className="text-2xl font-bold"
            icon="pi pi-save text-2xl"
            label="사진 업로드 하기"
            onClick={() => changetClaim()}
            disabled={dealerUser ? isCustomerUploadActive : isActive}
          />
        </div>
      </BlockUI>
    </div>
  );
};

export default PartnerUpload;
