import {
  PARTS_TOP_M,
  PARTS_LEFT_M,
  PARTS_RIGHT_M,
  PARTS_BOTTOM_M,
  ACCIDENT_STATUS,
  PARTS_ALL,
  PC_STATUS_BLUEPRINT,
  ACCIDENT_STATUS_TITLE,
  REPAIR_STATUS_TITLE,
  REPAIR_STATUS,
} from '../../constants/PerformanceCheckConstants';
import React, { useEffect, useState } from 'react';

import BPBottom from '../../assets/images/bp-bottom.png';
import BPLeft from '../../assets/images/bp-left.png';
import BPRight from '../../assets/images/bp-right.png';
import BPTop from '../../assets/images/bp-top.png';
import { CommonUtil } from '../../utils/commonUtil';
import _ from 'lodash';
import { useParams } from 'react-router-dom/cjs/react-router-dom';
import { ServiceProvider } from '../../services/index';
const performanceCheckService = ServiceProvider.performanceCheck;
const PerformanceCheckReportShort = () => {
  const searchParmas = useParams();
  const { dataId, statementNumber } = searchParmas;
  const [checkData, setCheckData] = useState(null);
  const [data, setData] = useState({
    paymentInformation: null,
    association: null,
    inspector: null,
    vehicleInformation: null,
    customerCompany: null,
  });
  const [accidentList, setAccidentList] = useState([]);
  const [oilList, setOilList] = useState([]);
  const [defecsList, setDefecsList] = useState([]);

  const getStatusLabels = () => {
    return checkData?.performanceStatus.map((item) => {
      const blueprint = PC_STATUS_BLUEPRINT[item.code];
      if (blueprint) {
        const valueItem = blueprint.valueItems?.find(
          (valueItem) => valueItem.value === item.status
        );
        if (valueItem) {
          return {
            title: blueprint.title,
            status: item.status,
            label: valueItem.label,
          };
        }
      }
      return {
        code: item.code,
        status: item.status,
        label: null, // or handle the case where no match is found
      };
    });
  };

  const findAccidentList = () => {
    let arr = [];
    PARTS_ALL.forEach(({ partPoint, title, dataKey }) => {
      if (ACCIDENT_STATUS[partPoint]) {
        if (ACCIDENT_STATUS[partPoint].includes(checkData[partPoint])) {
          arr.push({
            title: ACCIDENT_STATUS_TITLE[partPoint],
            value:
              checkData[partPoint] === '2'
                ? '교환'
                : checkData[partPoint] === '3'
                ? '판금,용접'
                : checkData[partPoint] === '4'
                ? '부식'
                : checkData[partPoint] === '5'
                ? '흠집'
                : checkData[partPoint] === '6'
                ? '요철'
                : '손상',
          });
        }
      } else if (REPAIR_STATUS[partPoint]) {
        if (REPAIR_STATUS[partPoint].includes(checkData[partPoint])) {
          arr.push({
            title: REPAIR_STATUS_TITLE[partPoint],
            value:
              checkData[partPoint] === '2'
                ? '교환'
                : checkData[partPoint] === '3'
                ? '판금,용접'
                : checkData[partPoint] === '4'
                ? '부식'
                : checkData[partPoint] === '5'
                ? '흠집'
                : checkData[partPoint] === '6'
                ? '요철'
                : '손상',
          });
        }
      }
    });
    return arr;
  };

  useEffect(() => {
    (async () => {
      const { data, error } =
        await performanceCheckService.getDataMobileByDataId(dataId);

      if (data) {
        const {
          performanceCheckData: {
            checkData: checkDataRaw,
            paymentInformation: paymentInformationRaw,
            association: associationRaw,
            inspector: inspectorRaw,
            customerCompany: customerCompanyRaw,
            vehicleInformation: vehicleInformationRaw,
          },
        } = data;

        const checkData = JSON.parse(checkDataRaw);
        const paymentInformation = JSON.parse(paymentInformationRaw);
        const association = JSON.parse(associationRaw);
        const inspector = JSON.parse(inspectorRaw);
        const customerCompany = JSON.parse(customerCompanyRaw);
        const vehicleInformation = JSON.parse(vehicleInformationRaw);

        setCheckData(checkData);
        setData((ps) => ({
          ...ps,
          paymentInformation,
          association,
          inspector,
          vehicleInformation,
          customerCompany,
        }));
      }

      if (error) {
        window.cerp.dialog.error(
          '존재하지 않는 성능지번호 입니다.',
          `[${statementNumber}]`
        );
        // setErrorData(error);
      }
    })();
  }, [dataId, statementNumber]);

  const setPartState = (value) => {
    let label = '';

    switch (value) {
      case '1':
        return '';
      case '2':
        return 'X'; // 교환
      case '3':
        return 'W'; // 판금
      case '4':
        return 'C'; // 부식
      case '5':
        return 'A'; // 흠집
      case '6':
        return 'U'; // 요철
      case '7':
        return 'T'; // 손상
      default:
    }

    return label;
  };

  useEffect(() => {
    if (checkData) {
      const result = getStatusLabels();
      const result2 = findAccidentList();
      setAccidentList(result2);
      if (result) {
        const x = result.filter((el) => el.label?.includes('불량'));
        setDefecsList(x);
        const u = result.filter(
          (el) => el.label?.includes('누유') || el.label?.includes('누수')
        );
        setOilList(u);
      }
    }
  }, [checkData]);

  return (
    <div
    //  ref={ref}
    // id="performance-check-report"
    // className="pcr"
    >
      <div className="pcr-short-wrap">
        <div className="pcr-short">
          <div className="flex justify-content-between mb-2">
            <div className="py-2">중고자동차 성능ㆍ상태점검기록부</div>
          </div>
          <div className="border-1">
            <div
              style={{
                background: '#F5F5F5',
                borderColor: '#DADADA',
              }}
              className="flex justify-content-center font-bold text-xl py-2 border-bottom-1"
            >
              최종 고지 리포트 (요약)
            </div>
            <div className="">
              <div className="flex border-bottom-1 h-2rem">
                <div
                  style={{
                    background: '#F5F5F5',
                  }}
                  className="w-6rem h-full flex align-items-center pl-2"
                >
                  차명
                </div>
                <div className="border-left-1 h-full flex align-items-center pl-2">
                  {data.vehicleInformation?.CNM}
                </div>
              </div>
              <div className="flex border-bottom-1 h-2rem">
                <div
                  style={{
                    background: '#F5F5F5',
                  }}
                  className="w-6rem h-full flex align-items-center pl-2"
                >
                  연식
                </div>
                <div className="border-left-1 h-full flex align-items-center pl-2">
                  {data.vehicleInformation?.PRYE}
                </div>
              </div>
              <div className="flex border-bottom-1 h-2rem">
                <div
                  style={{
                    background: '#F5F5F5',
                  }}
                  className="w-6rem h-full flex align-items-center pl-2"
                >
                  보증유형
                </div>
                <div className="border-left-1 h-full flex align-items-center pl-2">
                  {data.paymentInformation?.ASSRNC_TY_SE_CODE === '2'
                    ? '보험사보증 – 성능보험 접수가 가능한 차량입니다.'
                    : '자가보증'}
                </div>
              </div>
              <div className="flex border-bottom-1 h-2rem">
                <div
                  style={{
                    background: '#F5F5F5',
                  }}
                  className="w-6rem h-full flex align-items-center pl-2"
                >
                  현주행거리
                </div>
                <div className="border-left-1 h-full flex align-items-center pl-2">
                  {CommonUtil.Unit.format(data.vehicleInformation?.TRVL_DSTNC) +
                    'km'}
                </div>
              </div>
              <div className="flex border-bottom-1 h-2rem">
                <div
                  style={{
                    background: '#F5F5F5',
                  }}
                  className="w-6rem h-full flex align-items-center pl-2"
                >
                  튜닝여부
                </div>
                <div className="border-left-1 h-full flex align-items-center pl-2">
                  {data.vehicleInformation?.STMD_AT === 'N' ? '없음' : '있음'}
                </div>
              </div>
              <div className="flex border-bottom-1 h-2rem">
                <div
                  style={{
                    background: '#F5F5F5',
                  }}
                  className="w-6rem h-full flex align-items-center pl-2"
                >
                  특별이력
                </div>
                <div className="border-left-1 h-full flex align-items-center pl-2">
                  {checkData?.FLUD_AT === '0' ? '없음' : ''}
                </div>
              </div>
              <div className="flex border-bottom-1 h-2rem">
                <div
                  style={{
                    background: '#F5F5F5',
                  }}
                  className="w-6rem h-full flex align-items-center pl-2"
                >
                  용도변경
                </div>
                <div className="border-left-1 h-full flex align-items-center pl-2">
                  {data.vehicleInformation?.PRPOS_CHANGE_SE_AT === 'N'
                    ? '없음'
                    : '있음'}
                </div>
              </div>
            </div>

            <div className="flex flex-row justify-content-center mt-2">
              <div className="flex flex-row flex-wrap align-items-center justify-content-evenly relative">
                <div className="relative">
                  <img
                    src={BPLeft}
                    style={{
                      minHeight: '35mm',
                      height: '35mm',
                      maxHeight: '35mm',
                    }}
                    className="m-0 p-0 opacity-70"
                    alt={'차량이미지'}
                  />
                  {_.map(PARTS_LEFT_M, ({ partPoint, rpos: [top, left] }) => (
                    <div
                      key={`key_${partPoint}`}
                      // className="cerp-pp absolute text-center text-base font-bold"
                      className="cerp-pp absolute text-center font-bold"
                      style={{
                        top,
                        left,
                        color: 'rgb(204,51,0)',
                      }}
                    >
                      {setPartState(_.get(checkData, partPoint))}
                    </div>
                  ))}
                </div>
                <div className="relative">
                  <img
                    src={BPTop}
                    style={{
                      minHeight: '35mm',
                      height: '35mm',
                      maxHeight: '35mm',
                    }}
                    className="m-0 p-0 opacity-70"
                    alt={'차량이미지'}
                  />
                  {_.map(PARTS_TOP_M, ({ partPoint, rpos: [top, left] }) => (
                    <div
                      key={`key_${partPoint}`}
                      // className="cerp-pp absolute text-center text-base font-bold"
                      className="cerp-pp absolute text-center text-base font-bold"
                      style={{
                        top,
                        left,
                        color: 'rgb(204,51,0)',
                      }}
                    >
                      {setPartState(_.get(checkData, partPoint))}
                    </div>
                  ))}
                </div>
                <div className="relative">
                  <img
                    src={BPRight}
                    style={{
                      minHeight: '35mm',
                      height: '35mm',
                      maxHeight: '35mm',
                    }}
                    className="m-0 p-0 opacity-70"
                    alt={'차량이미지'}
                  />
                  {_.map(PARTS_RIGHT_M, ({ partPoint, rpos: [top, left] }) => (
                    <div
                      key={`key_${partPoint}`}
                      // className="cerp-pp absolute text-center text-base font-bold"
                      className="cerp-pp absolute text-center font-bold"
                      style={{
                        top,
                        left,
                        color: 'rgb(204,51,0)',
                      }}
                    >
                      {setPartState(_.get(checkData, partPoint))}
                    </div>
                  ))}
                </div>

                <div className="relative">
                  <img
                    src={BPBottom}
                    style={{
                      minHeight: '35mm',
                      height: '35mm',
                      maxHeight: '35mm',
                    }}
                    className="m-0 p-0 opacity-70"
                    // className="m-0 p-0"
                    alt={'차량이미지'}
                  />
                  {_.map(PARTS_BOTTOM_M, ({ partPoint, rpos: [top, left] }) => (
                    <div
                      key={`key_${partPoint}`}
                      // className="cerp-pp absolute text-center text-base font-bold"
                      className="cerp-pp absolute text-center font-bold"
                      style={{
                        top,
                        left,
                        color: 'rgb(204,51,0)',
                      }}
                    >
                      {setPartState(_.get(checkData, partPoint))}
                    </div>
                  ))}
                </div>
              </div>
            </div>

            <div className="my-2">
              <div className="ml-2">※ 상태표시 부호 (승용차에 준하여 표시)</div>
              <div className="ml-3 flex flex-row align-items-center justify-content-start gap-1">
                <div className="flex flex-row align-items-center justify-content-start gap-1">
                  <label
                    className="font-bold"
                    // style={{ color: 'rgb(204,51,0)' }}
                  >
                    X
                  </label>{' '}
                  (교환),
                </div>
                <div className="flex flex-row align-items-center justify-content-start gap-1">
                  <label
                    className="font-bold"
                    // style={{color: 'rgb(255,153,102)',}}
                  >
                    W
                  </label>{' '}
                  (판금,용접),
                </div>
                <div className="flex flex-row align-items-center justify-content-start gap-1">
                  <label
                    className="font-bold"
                    // style={{ color: 'rgb(255,204,0)' }}
                  >
                    C
                  </label>{' '}
                  (부식),
                </div>
                <div className="flex flex-row align-items-center justify-content-start gap-1">
                  <label
                    className="font-bold"
                    // style={{ color: 'rgb(153,204,51)' }}
                  >
                    A
                  </label>{' '}
                  (흠집),
                </div>
                <div className="flex flex-row align-items-center justify-content-start gap-1">
                  <label
                    className="font-bold"
                    // style={{ color: 'rgb(51,153,0)' }}
                  >
                    U
                  </label>{' '}
                  (요철),
                </div>
                <div className="flex flex-row align-items-center justify-content-start gap-1">
                  <label
                    className="font-bold"
                    // style={{ color: 'rgb(41,109,169)' }}
                  >
                    T
                  </label>{' '}
                  (손상)
                </div>
              </div>
            </div>
          </div>
          <div className="border-1 border-bottom-0 mt-3">
            <div
              style={{
                background: '#F5F5F5',
              }}
              className="flex justify-content-center py-2  border-bottom-1 "
            >
              <div>교환, 판금 등 이상 부위 표기</div>
            </div>
            <div>
              {accidentList?.length === 0 ? (
                <div className="border-bottom-1 p-2">이상 부위 없음</div>
              ) : (
                <div className="">
                  {accidentList.map((el, idx) => (
                    <div key={idx} className="flex border-bottom-1">
                      <div className="flex-1 p-2">{el.title}</div>
                      <div className="flex-1 p-2 border-left-1">{el.value}</div>
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>
          <div className="border-1 border-bottom-0 mt-3">
            <div
              style={{
                background: '#F5F5F5',
              }}
              className="flex justify-content-center py-2  border-bottom-1 "
            >
              <div>누유ㆍ누수 표기</div>
            </div>
            <div>
              {oilList?.length === 0 ? (
                <div className="border-bottom-1 p-2">누유ㆍ누수 없음</div>
              ) : (
                <div className="">
                  {oilList.map((el, idx) => (
                    <div key={idx} className="flex border-bottom-1">
                      <div className="flex-1 p-2">{el.title}</div>
                      <div className="flex-1 p-2 border-left-1">{el.label}</div>
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>
          <div className="border-1 border-bottom-0 mt-3">
            <div
              style={{
                background: '#F5F5F5',
              }}
              className="flex justify-content-center py-2  border-bottom-1 "
            >
              <div>불량표기</div>
            </div>
            <div>
              {defecsList?.length === 0 ? (
                <div className="border-bottom-1 p-2">불량 없음</div>
              ) : (
                <div className="">
                  {defecsList.map((el, idx) => (
                    <div key={idx} className="flex border-bottom-1">
                      <div className="flex-1 p-2">{el.title}</div>
                      <div className="flex-1 p-2 border-left-1">{el.label}</div>
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PerformanceCheckReportShort;
