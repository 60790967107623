import React, { forwardRef } from 'react';

import { Checkbox } from 'primereact/checkbox';

export const GroupTitledCheckbox = forwardRef((props, ref) => {
  const {
    name,
    title = '',
    value,
    items = [],
    onChange,
    error,
    readOnly = false,
  } = props;

  const getFormErrorMessage = () => {
    return error && <small className="p-error">{error?.message}</small>;
  };

  return (
    <>
      <div className="p-inputgroup h-full">
        <span
          className="h-full p-inputgroup-addon w-full font-medium text-center min-w-3 w-4 max-w-5 text-lg md:text-base"
          style={{
            display: 'table',
          }}
        >
          <span
            style={{
              display: 'table-cell',
              verticalAlign: 'middle',
              wordBreak: 'keep-all',
            }}
          >
            {title}
          </span>
        </span>
        <div className="p-inputgroup-addon bg-transparent flex flex-auto flex-wrap align-items-center justify-content-start gap-3">
          {items.map((item, idx) => (
            <div key={`${name}_${idx}`}>
              <div className="flex flex-auto align-items-center justify-content-start gap-1">
                <Checkbox
                  inputRef={ref}
                  inputId={`tc_${props.id || name}_${idx}`}
                  name={name}
                  value={item.value}
                  onChange={(e) => onChange(e.value)}
                  checked={value === item.value}
                  readOnly={readOnly}
                />
                <label
                  className={item.labelColor}
                  htmlFor={`tc_${props.id || name}_${idx}`}
                >
                  {item.label}
                </label>
              </div>
            </div>
          ))}
        </div>
      </div>
      {getFormErrorMessage()}
    </>
  );
});
