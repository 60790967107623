import React, { forwardRef } from 'react';

import { Dropdown } from 'primereact/dropdown';
import { classNames } from 'primereact/utils';

export const TitledDropdown = forwardRef((props, ref) => {
  const {
    id,
    title = '',
    value,
    options = [],
    optionLabel = 'label',
    optionValue = 'value',
    onChange,
    showFilter = false,
    filterBy = null,
    showClear = false,
    className = '',
    // readOnly = false,
    disabled = false,
    placeholder = '',
    error = null,
    required = false,
    optionTemplate = null,
    optionDisabled,
  } = props;

  return (
    <div className="field m-0">
      <label htmlFor={`txt_${id}`}>
        {required && <i className="text-red-400 pi pi-check mr-1" />}
        {title}
      </label>
      <Dropdown
        inputRef={ref}
        className={classNames(`w-full ${className}`, {
          'p-invalid': error?.message,
        })}
        // panelClassName={classNames('', {'p-invalid': error})}
        value={value}
        onChange={(e) => onChange(e.value)}
        options={options}
        optionLabel={optionLabel}
        optionValue={optionValue}
        filter={showFilter}
        filterBy={filterBy || 'label'}
        showClear={showClear}
        disabled={disabled}
        placeholder={placeholder}
        itemTemplate={optionTemplate || null}
        resetFilterOnHide
        emptyMessage="선택 가능한 옵션 없음"
        optionDisabled={optionDisabled || false}
      />
      <small id={`txt_err_${id}`} className="p-error">
        {error?.message}
      </small>
    </div>
  );
});
