import React, { forwardRef } from 'react';

import { RadioButton } from 'primereact/radiobutton';
import { classNames } from 'primereact/utils';

export const GroupTitledRadioButton = forwardRef((props, ref) => {
  const {
    name,
    title = '',
    value,
    items = [],
    onChange,
    error,
    disabled = false,
    readOnly = false,
  } = props;

  return (
    <>
      <div className={classNames('p-inputgroup h-full', { 'p-error': error })}>
        <div className="h-full w-full justify-content-center font-medium text-center min-w-3 w-4 max-w-5 flex align-items-center p-inputgroup-addon text-lg md:text-base">
          {title}
        </div>
        <div
          className={classNames(
            'p-inputgroup-addon bg-transparent flex flex-auto flex-wrap align-items-center justify-content-start gap-3 h-full',
            { 'bg-gray-100': disabled }
          )}
        >
          {items.map((item, idx) => (
            <div key={`${name}_${idx}`}>
              <div className="flex flex-auto align-items-center justify-content-start gap-1">
                <RadioButton
                  inputRef={ref}
                  inputId={`trb_${props.id || name}_${idx}`}
                  name={name}
                  value={item.value}
                  onChange={(e) => onChange(e.value)}
                  checked={value === item.value}
                  disabled={disabled}
                  readOnly={readOnly}
                />
                <label
                  className={item.labelColor}
                  htmlFor={`trb_${props.id || name}_${idx}`}
                >
                  {item.label}
                </label>
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
});
