import React, { useEffect, useMemo, useState } from 'react';

import { Button } from 'primereact/button';
import { COUNT_PER_PAGE } from '../../../constants/Constants';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Dialog } from 'primereact/dialog';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { MyInfoUtil } from '../../../utils/myInfoUtil';
import { ServiceProvider } from '../../../services';
import UserPosition from '../../../enums/UserPosition';
import _ from 'lodash';

const DEFAULT_SEARCH_CONDITIONS = {
  associationId: 0,
  enterpriseId: 0,
  shopId: 0,
  customerCompanyId: 0,
  searchText: '',
};

const DEFAULT_LAZY_PARAMS = {
  first: 0,
  rows: 10,
  page: 0,
};

const codeService = ServiceProvider.code;
const searchService = ServiceProvider.search;

export const CustomerSearchDialog = ({ currentInspector, onHide }) => {
  const {
    associationId: inspectorAssociationId,
    associationName: inspectorAssociationName,
    enterpriseId: inspectorEnterpriseId,
    enterpriseName: inspectorEnterpriseName,
    shopId: inspectorShopId,
    shopName: inspectorShopName,
    userPosition: inspectorUserPosition,
    roleCode: inspectorRoleCode,
  } = useMemo(() => ({ ...currentInspector }), [currentInspector]);

  const [loading, setLoading] = useState(false);
  const [searchConditions, setSearchConditions] = useState({
    ...DEFAULT_SEARCH_CONDITIONS,
  });
  const [codes, setCodes] = useState({
    associations: [],
    enterprises: [],
    shops: [],
  });

  const [totalRecords, setTotalRecords] = useState(0);
  const [lazyParams, setLazyParams] = useState({ ...DEFAULT_LAZY_PARAMS });
  const [data, setData] = useState([]);

  const getAssociationCodes = async ({
    associationId,
    preSelectedAssociationId,
  }) => {
    const inspectorAssociation = {
      value: inspectorAssociationId,
      label: inspectorAssociationName,
    };

    return [
      [inspectorAssociation],
      _.get(inspectorAssociation, 'value'),
      inspectorAssociation,
    ];
  };

  const getEnterpriseCodes = async ({
    associationId,
    preSelectedEnterpriseId,
  }) => {
    const inspectorEnterprise = {
      value: inspectorEnterpriseId,
      label: inspectorEnterpriseName,
    };

    return [
      [inspectorEnterprise],
      _.get(inspectorEnterprise, 'value'),
      inspectorEnterprise,
    ];
  };

  const getShopCodes = async ({ enterpriseId = 0, preSelectedShopId } = {}) => {
    const inspectorShop = {
      value: inspectorShopId,
      label: inspectorShopName,
    };
    return [[inspectorShop], _.get(inspectorShop, 'value'), inspectorShop];
  };

  const getCustomerCompanyCodes = async ({
    associationId,
    enterpriseId,
    shopId,
  }) => {
    if (!shopId) return [[{ value: '', label: '전체매매상사' }], '', {}];

    const { data } = await codeService.customerCompanies(
      associationId,
      enterpriseId,
      shopId
    );
    const customerCompanies = [{ value: '', label: '전체매매상사' }, ...data];
    const customerCompany = _.get(customerCompanies, 0);

    return [
      customerCompanies,
      _.get(customerCompany, 'value'),
      _.get(customerCompany, 'codeData'),
    ];
  };

  const getCustomers = async (conditions, page, size) => {
    const {
      associationId,
      enterpriseId,
      shopId,
      customerCompanyId,
      searchText,
    } = conditions;

    setLoading(true);

    const params = {
      aid: associationId,
      eid: enterpriseId,
      sid: shopId,
      ccid: customerCompanyId,
      st: searchText,
      page: page + 1,
      size: size,
    };

    try {
      const { data } = await searchService.customers(params);
      if (data) {
        setTotalRecords(data.total);
        setData(data.list);
      }
    } catch (error) {
      window.cerp.dialog.error(
        '조회실패',
        `[${error?.code}] ${error?.message}`
      );
    }

    setLoading(false);
  };

  const onClickSearch = (conditions, page, size) => {
    getCustomers(conditions, page, size);
  };

  const onInit = () => {
    setData([]);
  };

  useEffect(() => {
    (async () => {
      const [
        [associations, associationId],
        [enterprises, enterpriseId],
        [shops, shopId],
      ] = await Promise.all([
        getAssociationCodes({
          preSelectedAssociationId: inspectorAssociationId,
        }),
        getEnterpriseCodes({
          preSelectedEnterpriseId: inspectorEnterpriseId,
        }),
        getShopCodes({
          preSelectedShopId: inspectorShopId,
        }),
      ]);
      const [customerCompanies, customerCompanyId] =
        await getCustomerCompanyCodes({ associationId, enterpriseId, shopId });

      if (_.get(currentInspector, 'userName') !== '' && customerCompanyId) {
        await getCustomers(
          {
            ...searchConditions,
            associationId,
            enterpriseId,
            shopId,
            customerCompanyId,
          },
          _.get(DEFAULT_LAZY_PARAMS, 'page'),
          _.get(DEFAULT_LAZY_PARAMS, 'rows')
        );
      }

      setCodes({ associations, enterprises, shops, customerCompanies });
      setSearchConditions((ps) => ({
        ...ps,
        associationId,
        enterpriseId,
        shopId,
        customerCompanyId,
      }));
    })();
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inspectorAssociationId, inspectorEnterpriseId, inspectorShopId]);

  return (
    <Dialog
      visible
      modal
      header="고객 검색"
      style={{ width: '72vw' }}
      breakpoints={{ '960px': '84vw', '640px': '96vw' }}
      onHide={onHide}
    >
      <div className="grid mb-0">
        <div className="col-12 sm:col-6 lg:col-3">
          <div className="field mb-0">
            <label>소속협회</label>
            <Dropdown
              className="w-full"
              value={_.get(searchConditions, 'associationId')}
              options={codes.associations}
              onChange={async ({ value }) => {
                console.group('[협회 변경]');
                setLoading(true);

                const [enterprises, enterpriseId] = await getEnterpriseCodes({
                  associationId: value,
                });
                const [shops, shopId] = await getShopCodes({
                  enterpriseId,
                });

                onInit();
                setCodes((ps) => ({ ...ps, enterprises, shops }));
                setSearchConditions((ps) => ({
                  ...ps,
                  associationId: value,
                  enterpriseId,
                  shopId,
                }));

                setLoading(false);
                console.groupEnd();
              }}
              disabled={
                !_.includes([UserPosition.Erp], inspectorUserPosition) ||
                MyInfoUtil.isInspector(inspectorRoleCode)
              }
              filter
              placeholder={
                loading ? (
                  <>
                    <i className="pi pi-spin pi-spinner m-0 mr-2" />
                    조회하고 있습니다...
                  </>
                ) : (
                  '협회가 없습니다.'
                )
              }
              emptyMessage="선택 가능한 옵션 없음"
            />
          </div>
        </div>
        <div className="col-12 sm:col-6 lg:col-3">
          <div className="field mb-0">
            <label>소속점검법인</label>
            <Dropdown
              className="w-full"
              value={_.get(searchConditions, 'enterpriseId')}
              options={codes.enterprises}
              onChange={async ({ value }) => {
                console.group('[점검업체 변경]');
                setLoading(true);

                const [shops, shopId] = await getShopCodes({
                  enterpriseId: value,
                });

                onInit();
                setCodes((ps) => ({ ...ps, shops }));
                setSearchConditions((ps) => ({
                  ...ps,
                  enterpriseId: value,
                  shopId,
                }));

                setLoading(false);
                console.groupEnd();
              }}
              disabled={
                !_.includes(
                  [UserPosition.Erp, UserPosition.Association],
                  inspectorUserPosition
                ) || MyInfoUtil.isInspector(inspectorRoleCode)
              }
              filter
              placeholder={
                loading ? (
                  <>
                    <i className="pi pi-spin pi-spinner m-0 mr-2" />
                    조회하고 있습니다...
                  </>
                ) : (
                  '점검법인이 없습니다.'
                )
              }
              emptyMessage="선택 가능한 옵션 없음"
            />
          </div>
        </div>
        <div className="col-12 sm:col-6 lg:col-3">
          <div className="field mb-0">
            <label>소속점검장</label>
            <Dropdown
              className="w-full"
              value={_.get(searchConditions, 'shopId')}
              options={codes.shops}
              onChange={async ({ value }) => {
                console.group('[점검장 변경]');
                setLoading(true);

                // setData([]);
                onInit();
                setSearchConditions((ps) => ({ ...ps, shopId: value }));

                setLoading(false);
                console.groupEnd();
              }}
              disabled={
                !_.includes(
                  [
                    UserPosition.Erp,
                    UserPosition.Association,
                    UserPosition.Enterprise,
                  ],
                  inspectorUserPosition
                ) || MyInfoUtil.isInspector(inspectorRoleCode)
              }
              filter
              placeholder={
                loading ? (
                  <>
                    <i className="pi pi-spin pi-spinner m-0 mr-2" />
                    조회하고 있습니다...
                  </>
                ) : (
                  '점검장이 없습니다.'
                )
              }
              emptyMessage="선택 가능한 옵션 없음"
            />
          </div>
        </div>

        <div className="col-12 sm:col-6 lg:col-3">
          <div className="field mb-0">
            <label>소속매매상사</label>
            <Dropdown
              className="w-full"
              value={_.get(searchConditions, 'customerCompanyId')}
              options={codes.customerCompanies}
              onChange={async ({ value }) => {
                console.group('[매매상사 변경]');
                setLoading(true);

                onInit();
                // setData([]);
                setSearchConditions((ps) => ({
                  ...ps,
                  customerCompanyId: value,
                }));
                setLoading(false);
                console.groupEnd();
              }}
              filter
              placeholder={
                loading ? (
                  <>
                    <i className="pi pi-spin pi-spinner m-0 mr-2" />
                    조회하고 있습니다...
                  </>
                ) : (
                  '매매상사가 없습니다.'
                )
              }
              emptyMessage="선택 가능한 옵션 없음"
            />
          </div>
        </div>
        <div className="col-12">
          <div className="field">
            <label>검색키워드</label>
            <div className="p-inputgroup">
              <InputText
                autoFocus
                value={searchConditions.searchText}
                onChange={(e) =>
                  setSearchConditions((ps) => ({
                    ...ps,
                    searchText: e.target.value,
                  }))
                }
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    onClickSearch(
                      searchConditions,
                      lazyParams.page,
                      lazyParams.rows
                    );
                  }
                }}
              />
              <Button
                icon="pi pi-search"
                onClick={() =>
                  onClickSearch(
                    searchConditions,
                    lazyParams.page,
                    lazyParams.rows
                  )
                }
              />
            </div>
          </div>
        </div>
      </div>

      <DataTable
        size="small"
        loading={loading}
        value={data}
        lazy
        rows={lazyParams.rows}
        first={lazyParams.first}
        totalRecords={totalRecords}
        paginator
        responsiveLayout="scroll"
        paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
        currentPageReportTemplate="전체 {totalRecords}건 중 {first} ~ {last}"
        rowsPerPageOptions={COUNT_PER_PAGE}
        onPage={(event) => {
          setLazyParams(event);
          onClickSearch(searchConditions, event.page, event.rows);
        }}
        resizableColumns
        showGridlines
        selectionPageOnly={true}
        emptyMessage={
          _.get(currentInspector, 'userName') === ''
            ? '성능점검자를 선택하세요.'
            : '데이터가 없습니다.'
        }
        rowHover
        rowClassName="cursor-pointer"
        onRowDoubleClick={({ data }) => onHide(data)}
      >
        <Column field="customerName" header="고객명" />
        <Column field="companyName" header="매매상사" />
        <Column field="shopName" header="점검장" />
        <Column field="userName" header="사용자" />
      </DataTable>
    </Dialog>
  );
};
